import request from '@/router/axios';
import { pvhApiBase } from '@/config/env';


//��ȡ���۶����б���
export const saleOrderList = (page, pageSize, businessnoin, businessnoout, so, from, code, batch, color, com, beginOrderDate, endOrderDate, status, otherStatus, traingStatus, UTbegin, UTend) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrders",
        method: "get",
        params: {
            "businessnoin": businessnoin,
            "businessnoout": businessnoout,
            "so": so,
            "from": from,
            "code": code,
            "batch": batch,
            "color": color,
            "completion": com,
            "beginOrderDate": beginOrderDate,
            "endOrderDate": endOrderDate,
            "status": status,
            "otherStatus": otherStatus,
            "traingStatus": traingStatus,
            "UTbegin": UTbegin,
            "UTend": UTend,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//��ȡ���۵�����
export const SaleInfo = (id,orderId, brandBusinessId,vendorSectionId) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrderCanRead",
        method: "get",
        params: {
            "id": id,
            "orderId": orderId,
            "brandBusinessId": brandBusinessId,
            "vendorSectionId": vendorSectionId
        }
    })
}

//����Batch��ƥ������
export const CodeList = (Code) => {
    return request({
        url: pvhApiBase + "Tracing/GetCodeList",
        method: "get",
        params: {
            "Code": Code
        }
    })
}

//�ж��Ƿ��ܹ���ѡ��Ʒ���̹����Ƿ�����
export const verificationInScope = (PurchaseOrderDetailId, SaleOrderDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/GetVerificationInScope",
        method: "get",
        params: {
            "PurchaseOrderDetailId": PurchaseOrderDetailId,
            "SaleOrderDetailId": SaleOrderDetailId
        }
    })
}

//���۵�ѡ�����ʹ�õĲɹ���
export const CanUesPos = (page, pageSize) => {
    return request({
        url: pvhApiBase + "Tracing/GetSaleOrdersCanUesPos",
        method: "get",
        params: {
            "page": page,
            "pageSize": pageSize
        }
    })
}


//��ȡ��ʹ�õ�po�����Ϣ
export const poCanUserStockList = (page, pageSize, vendor, code, batch, color,  beginTime, endTime) => {
    return request({
        url: pvhApiBase + "Tracing/GetPoCanUserStocks",
        method: "get",
        params: {
            "code": code,
            "batch": batch,
            "color": color,
            "vendor": vendor,
            "beginOrderDate": beginTime,
            "endOrderDate": endTime,
            "page": page,
            "pageSize": pageSize
        }
    })
}

//��ȡ��po�������ʣ������
export const stockNum = (PurchaseOrderDetailId) => {
    return request({
        url: pvhApiBase + "Tracing/GetStockNum",
        method: "get",
        params: {
            "PurchaseOrderDetailId": PurchaseOrderDetailId
        }
    })
}
//��֤ʹ���������ڿ��
export const checkInventory = (PurchaseOrderDetailId, DetailId, num, way) => {
    return request({
        url: pvhApiBase + "Tracing/CheckInventory",
        method: "get",
        params: {
            "Id": PurchaseOrderDetailId, 
            "DetailId": DetailId,
            "Num": num,
            "Way": way
        }
    })
}

//��ȡSO��ʹ�õĿ��
export const soUserStockList = (page, pageSize, soDetailID) => {
    return request({
        url: pvhApiBase + "Tracing/GetSoUserStocks",
        method: "get",
        params: {
            "soDetailID": soDetailID,
            "page": page,
            "pageSize": pageSize
        }
    })
}



//����
export const addRalation = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PostOrderRalation",
        method: "post",
        data: {
            ...params
        }
    })
}

//�༭
export const editRalation = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutOrderRalation",
        method: "put",
        data: {
            ...params
        }
    })
}

//ɾ��
export const delRalation = (id) => {
    return request({
        url: pvhApiBase + "Tracing/DeleteOrderRalation",
        method: "delete",
        params: {
            id
        }
    })
}



//�༭
export const editSale = (params) => {
    console.log(params)
    return request({
        url: pvhApiBase + "Tracing/PutSaleOrder",
        method: "put",
        data: {
            ...params
        }
    })
}

//�༭
export const editHeadSO = (params) => {
    console.log(params);
    return request({
        url: pvhApiBase + "Tracing/PutSoInfo",
        method: "put",
        data: {
            ...params
        }
    })
}

//�༭
export const editDetailSO = (params) => {
    console.log(params);
    return request({
        url: pvhApiBase + "Tracing/PutSoDetailInfo",
        method: "put",
        data: {
            ...params
        }
    })
}
