// 公用接口
import request from '@/router/axios';
import { pvhApiBase, businessUrl } from '@/config/env';
//纺纱 下拉框
export const ProcessDropDownList = (remark, param1, param2) => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetUnifiedDropDowns",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2
        }
    })

}
//追溯 下拉框
export const TracingDropDownList = (remark, param1, param2) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedDropDowns",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2
        }
    })

}

//追溯 返回是否
export const TracingUnifiedIsBool = (remark, id, param1, param2, param3) => {
    return request({
        url: pvhApiBase + "Tracing/UnifiedReturnBool",
        method: "get",
        params: {
            "remark": remark,
            "id": id,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })

}

//追溯 帮助返回错误详情
export const TracingUnifiedHelpReturn = (remark, id, param1, param2, param3) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedHelpReturn",
        method: "get",
        params: {
            "remark": remark,
            "id": id,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })

}

//追溯 返回报表数据
export const TracingCharts = (remark, param1, param2, param3, param4, param5, param6, param7) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedChartsReturn",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2,
            "param3": param3,
            "param4": param4,
            "param5": param5,
            "param6": param6,
            "param7": param7,
        }
    })

}

//获取当前公司信息
export const corpinfo = () => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCurrCorporation",
        method: "get",
    })

}
//获取参数公司信息
export const Getcorpinfo = (id) => {
    return request({
        url: pvhApiBase + "ProcessManagement/GetCorporationById",
        method: "get",
        params: {
            "id": id,
        }
    })

}
Getcorpinfo
//根据类别获取 List<string>
export const TracingStringList = (remark, param1, param2, param3) => {
    return request({
        url: pvhApiBase + "Tracing/GetUnifiedStringList",
        method: "get",
        params: {
            "remark": remark,
            "param1": param1,
            "param2": param2,
            "param3": param3,
        }
    })
}


export function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function Getguid() {
    return (S4() + S4() + "-" +S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

//新增物料
export const ProcessAddProduct = (row) => request({
    url: businessUrl + "product/pvhInsert",
    method: "post",
    data: {
        ...row
    }
})

//获取版本号
export const GetBusinessNoIn = (code,corpId) => request({
    url: businessUrl + "codeRule/getStringByRuleCode",
    method: "post",
    params: {
        "code": code,
        "corpId": corpId,
    }
})
